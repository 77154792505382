import { useState } from "react";
import "./NotFound.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import FigmaEmbed from "../../components/FigmaEmbed/FigmaEmbed";
import Button from "../../components/Button/Button";

function NotFound() {
    const [showFigma, setShowFigma] = useState(false);

    const handleShowFigma = () => {
        setShowFigma(true);
    };

    return (
        <div className="page-layout">
            <Header />
            <div className="page-layout__wrapper">
                <div className="page-layout__main-figma">
                    <div className="page-layout__header-container">
                        <img 
                            src="https://res.cloudinary.com/dhifyp04n/image/upload/v1744252061/xiaoho_1_mi1k68.png"
                            className="page-layout__image"
                            alt="It's my dog's headshot"
                        />

                        {!showFigma && (
                            <>
                                <h1 className="page-layout__header">
                                    Oops… <br />You’ve wandered off the leash!
                                </h1>
                                <p className="page-layout__text"> 
                                    {`Looks like this page doesn’t exist anymore (or maybe it never did).`}
                                    <br /><br />
                                    {`But don’t worry, you’re not totally lost, my dog’s here to keep you company. Try your luck with this memory game and see how fast you can match all the doggy pics!`}
                                </p>
                                <Button 
                                    text="Play Memory Game" 
                                    type="primary" 
                                    buttonWidth="medium" 
                                    onClick={handleShowFigma} 
                                />
                            </>
                        )}
                    </div>

                    {showFigma && (
                        <FigmaEmbed 
                            src="https://embed.figma.com/proto/0sDcKmrYb5Kc5QcK1HQuTE/404?page-id=0%3A1&node-id=20-511&viewport=561%2C650%2C0.58&scaling=scale-down&content-scaling=fixed&starting-point-node-id=20%3A511&hotspot-hints=0&embed-host=share"
                            className="page-layout__figma"
                        />
                    )}
                </div>

                <Footer />
            </div>
        </div>
    );
}

export default NotFound;