import "./MultiForm.scss";
import Footer from "../../../../components/Footer/Footer";
import Header from "../../../../components/Header/Header";

function MultiForm(){
    return(
        <div className="page-layout">
            <Header />
            <main className="page-layout-main">



                <Footer 
                    noBackgroundColor="true"
                />
            </main>
        </div>
    );
};

export default MultiForm;