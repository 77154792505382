import { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Home from './pages/Home/Home';
import AboutMe from './pages/AboutMe/AboutMe';
import Gallery from './pages/Gallery/Gallery';
import ProtectedGalleryRoute from './templates/ProtectedGalleryRoute/ProtectedGalleryRoute.jsx';
import './App.css';
import NotFound from './pages/NotFound/NotFound.jsx';

// Capital One
import Pdp from './pages/Projects/CapitalOne/Pdp/Pdp';
import MultiForm from './pages/Projects/CapitalOne/MultiForm/MultiForm';
import Lighthouse from './pages/Projects/CapitalOne/Lighthouse/Lighthouse.jsx';

// Helcim
import Ach from './pages/Projects/Helcim/Ach/Ach';
import Email from './pages/Projects/Helcim/Email/Email';

// Side project
import Redyy from './pages/Projects/SideProject/Redyy/Redyy.jsx';

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    const redirectedPath = sessionStorage.getItem('redirect');
    if (redirectedPath) {
      sessionStorage.removeItem('redirect');
      navigate(redirectedPath, { replace: true });
    }
  }, [navigate]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about_me" element={<AboutMe />} />
      <Route path="/gallery" element={<Gallery />} />
      <Route path="/404" element={<NotFound />} />

      <Route
        path="/gallery/email"
        element={
          <ProtectedGalleryRoute>
            <Email />
          </ProtectedGalleryRoute>
        }
      />
      <Route
        path="/gallery/ach"
        element={
          <ProtectedGalleryRoute>
            <Ach />
          </ProtectedGalleryRoute>
        }
      />
      <Route
        path="/gallery/lighthouse"
        element={
          <ProtectedGalleryRoute>
            <Lighthouse />
          </ProtectedGalleryRoute>
        }
      />
      <Route
        path="/gallery/pdp"
        element={
          <ProtectedGalleryRoute>
            <Pdp />
          </ProtectedGalleryRoute>
        }
      />
      <Route
        path="/gallery/side-project/redyy"
        element={
          <ProtectedGalleryRoute>
            <Redyy />
          </ProtectedGalleryRoute>
        }
      />

      <Route path="/gallery/multi-form" element={<MultiForm />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;