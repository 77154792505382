import "./Pdp.scss";
import Footer from "../../../../components/Footer/Footer";
import Header from "../../../../components/Header/Header";
import ProjectHero from "../../../../templates/ProjectHero/ProjectHero";
import ContentBlock from "../../../../templates/ContentBlock/ContentBlock";
import FullWidthImageSection from "../../../../templates/FullWidthImageSection/FullWidthImageSection";
import ProjectPanelDisplay from "../../../../templates/ProjectPanelDisplay/ProjectPanelDisplay";
import AchExample from "../../../../components/AchExample/AchExample";

import achFull from "../../../../assets/images/projects/ach/ach_full_width.jpg";
import achPreview1 from "../../../../assets/images/projects/ach/ach_1.jpg";
import achPreview2 from "../../../../assets/images/projects/ach/ach_2.jpg";
import achPreview3 from "../../../../assets/images/projects/ach/ach_3.jpg";
import achPreview4 from "../../../../assets/images/projects/ach/ach_4.jpg";
import achPreview5 from "../../../../assets/images/projects/ach/ach_5.png";
import achPreview6 from "../../../../assets/images/projects/ach/ach_6.png";
import achPreview7 from "../../../../assets/images/projects/ach/ach_7.png";
import achPreview8 from "../../../../assets/images/projects/ach/ach_8.jpg";
import achPreview9 from "../../../../assets/images/projects/ach/ach_9.png";


function Pdp(){
    return(
        <div className="page-layout">
            <Header />
            <main className="page-layout-main">
                <section className="page-layout-main__project-section">
                    <ProjectHero 
                        tag="Product Redesign"
                        header="PDP Refresh"
                        body="The Capital One Canada product detail page (PDP) had not been updated in a long time and no longer aligned with the current brand direction. While the existing design served its purpose for years, it felt visually disconnected. We took on a complete redesign to bring the PDP back in line with Capital One’s evolving brand and ensure a more cohesive experience."
                        company="Capital One"
                        role="Product Designer"
                        year="2022"
                        src="https://res.cloudinary.com/dhifyp04n/image/upload/v1744259595/Frame_2_dh4nij.png"
                        alt="A preview of the Capital One product detail page"
                    />

                    <ContentBlock 
                        isText={true}
                        isImage={false}
                        isList={false}
                        header="Context"
                        body="Currently, the Virtual Terminal (Merchant Platform) supports credit card and other tender types, but lacks a bank payment option. A comprehensive competitive analysis revealed that not offering this feature could negatively impact sign-up traffic and lead to the loss of potential clients. By introducing EFT/ACH transactions, we aim to give merchants greater flexibility in choosing the payment methods that best suit their business needs."
                    />

                    <ContentBlock 
                        isText={true}
                        isImage={false}
                        isList={false}
                        header="Research"
                        body="This feature release involves integrating ACH payments into our existing Virtual Terminal platform. Since ACH is already available on MSP, we explored the possibility of repurposing the current ACH payment page, applying a simplified and minimal design to avoid user confusion"
                    />

                    <ContentBlock 
                        isText={true}
                        isImage={false}
                        isList={true}
                        useXIcon={true}
                        header=""
                        body="Key insights from our competitor research include:"
                        listItems={[
                            "Complex and unclear user flows",
                            "Poor navigation structure",
                            "Inconsistent and fragmented user experiences",
                            "Unconventional or unintuitive interactions"
                        ]}
                    />

                    <ContentBlock 
                        isText={true}
                        isImage={false}
                        isList={false}
                        header=""
                        body={`There is extended logic required in this integration. We need to carefully consider both the merchant-facing and customer-facing flows and ensure they’re well-connected to deliver a seamless experience. Our goal is to prevent friction and reduce frustration for merchants during the process.\n\nAdditionally, this presents a strong opportunity to improve the user experience specifically around the bank input fields, making them clearer and easier to use.`}
                    />

                    <FullWidthImageSection 
                        image={achFull}
                        variant="image-only"
                    />

                    <ProjectPanelDisplay
                        header="Payment page integration"
                        body="Integrating the bank payment feature into the Pay Page allows merchants to accept bank transfers directly from customers. This adds flexibility and gives customers the option to choose their preferred payment method."
                        image={achPreview1}
                        alt="Screenshots of the Helcim payment page, one for U.S. customers and one for Canadian customers."
                        isReverse={false}
                    />

                    <ProjectPanelDisplay
                        header="Invoicing integration"
                        body="Adding bank payment functionality to the invoicing system enables merchants to accept bank transfers through digital invoices, streamlining the payment collection process."
                        image={achPreview2}
                        alt="Screenshot of the Helcim invoicing system with the ACH payment modal highlighted."
                        isReverse={true}
                    />

                    <ProjectPanelDisplay
                        header="Customer integration"
                        body="Allowing bank account details to be added within the customer portal gives merchants the ability to store or request bank payment information directly from their customers."
                        image={achPreview3}
                        alt="Screenshot of the Helcim customer intergration page"
                        isReverse={false}
                    />

                    <ProjectPanelDisplay
                        header="Virtual terminal integration"
                        body="Integrating bank payments into the Virtual Terminal allows merchants to add new customers and process transactions using their bank account information within the bank payment terminal."
                        image={achPreview4}
                        alt="Screenshots of the Helcim virtual terminal for ACH payment."
                        isReverse={true}
                    />

                    <ContentBlock
                        header="Interactive Virtual Cheque"
                        body="One of our main improvements during the ACH flow redesign was incorporating an interactive virtual cheque. This feature makes information entry more engaging and intuitive. Based on our research, the majority of U.S. merchants still frequently use cheques. To help them associate familiar elements with the digital terminal, we introduced a hover-and-click cheque interface. As users interact with different parts of the cheque, corresponding fields in the form are highlighted, guiding them through the process step by step."
                        isVideo={true}
                        isText={true}  
                        isImage={false}
                        videoSrc="https://res.cloudinary.com/dhifyp04n/video/upload/v1744079160/Comp_1_rbro5d.mp4"
                    />

                    <ContentBlock 
                        isText={true}
                        isImage={true}
                        isList={false}
                        header="Iterations"
                        body="The most time-consuming part of the design process was ensuring the UI of the virtual cheque aligned with Helcim’s design patterns. At the same time, it had to be feasible for UX engineers to implement and intuitive enough for users to understand effortlessly."
                        image={achPreview5}
                        alt="Preview of early ACH design explorations and interface concepts."
                    />

                    <ProjectPanelDisplay
                        header=""
                        body="While referencing a real Canadian cheque, we found that including the cheque number added unnecessary complexity and created challenges for implementation. It also made the virtual cheque too long, impacting responsiveness. To streamline the experience, we removed non-essential details, making the design cleaner and more mobile-friendly."
                        image={achPreview9}
                        alt="Highlighting the design difference between including a cheque number vs. leaving it out."
                        isReverse={true}
                    />

                    <ContentBlock 
                        isText={false}
                        isImage={true}
                        isList={false}
                        image={achPreview6}
                        alt="Visual overview of the ACH payment flow from both merchant and customer perspectives."
                    />

                    <ProjectPanelDisplay
                        header=""
                        body="Here’s a quick demo, give it a try! On desktop, where most of our users are, the virtual cheque is interactive through hover and click. Users can interact with both the cheque and form fields, with each action reflecting the relevant information they need to fill out."
                        customComponent={<AchExample />}
                        isReverse={true}
                    />

                    <ContentBlock 
                        isText={true}
                        isImage={true}
                        isList={false}
                        header="More in the project"
                        body="One of the high-impact tasks in this project was redesigning the email template. The existing system emails weren’t visually aligned with our marketing email campaigns, so we took this opportunity to create a more cohesive and consistent email design system."
                        image={achPreview7}
                        alt="Redesigned email template supporting ACH transactions, aligned with the updated design system."
                    />

                    <ContentBlock 
                        isText={true}
                        isImage={true}
                        isList={false}
                        header="Outcome"
                        body={`ACH was a high-impact project and a key recent feature release. Although we had limited time and resources, the overall process was smooth, and I learned a lot throughout the experience.\n\nI worked closely with the product manager and development team to define the logic and user flow for the ACH system. As things progressed, we felt confident enough to hand off the work to the development team for implementation.\n\nThe user flow went through several iterations—especially on the Canadian side, where complex PAD regulations required us to make ongoing adjustments. These changes helped improve the experience and reduce confusion for Canadian users, making the entire process more efficient and user-friendly.`}
                        image={achPreview8}
                        alt="Full preview of the ACH project, including all detailed UI screens and final designs."
                    />
                        
                    <ContentBlock 
                        isText={true}
                        isImage={false}
                        isList={false}
                        header="Key takeaways "
                        bodies={[
                            {
                            subheader: "Always prepare mobile mockups 📱",
                            body: "Early on, I was told not to design mobile versions since we had a shared system in place. But without proper mockups, UX engineers often guessed, leading to awkward layouts. I learned that even simple mobile mockups can go a long way in aligning expectations and avoiding unnecessary back-and-forth."
                            },
                            {
                            subheader: "Don’t compromise on good UX 💡",
                            body: "Technical limitations happen, but I’ve learned to advocate for user experience. When developers understand why a detail matters, they’re often willing to find solutions. It’s all about communication and working together to strike the right balance."
                            },
                            {
                            subheader: "Handoff isn’t the end — it’s just the beginning 🥹",
                            body: "After handoff, legal or regional requirements can lead to changes. For example, Canada’s EFT PAD rules meant adjusting the flow to include important disclaimers. Staying involved post-handoff helped ensure both compliance and a smooth user experience."
                            }
                        ]}
                    />
                </section>

                <Footer 
                    noBackgroundColor="true"
                />
            </main>
        </div>
    );
};

export default Pdp;